<template>
  <van-field
      :name="name"
      v-model="this.$props.value"
      v-show="false"
  >
  </van-field>
  <van-cell>
    <van-checkbox-group v-model="checked" direction="horizontal">
      <van-checkbox v-for="item in dataList" :key="item" :name=item>{{ item }}</van-checkbox>
    </van-checkbox-group>
  </van-cell>
</template>

<script>
export default {
  name: "DoorCheckBoxes",

  components: {},

  props: {
    name: {
      default: '',
      type: String
    },
    value: {
      default: '',
      type: String
    },
    dataList: {
      default: function () {
        return []
      },
      type: Array
    }
  },

  emits: ['update:value'],

  setup() {
  },

  data() {
    return {
      checked: [],
    };
  },

  computed: {},

  watch: {
    checked: function (val, oldval) {
      let value = '';
      this.dataList.forEach(v => {
        value = value + v + (val.indexOf(v) !== -1 ? '有' : '无');
      })
      this.$emit('update:value', value);
    },
    value: function (val, oVal) {
      let arr = [];
      while (val.indexOf('有') !== -1) {
        arr.push(val.substring(val.indexOf('有') - 2, val.indexOf('有')));
        val = val.substring(val.indexOf('有') + 1, val.length);
      }
      if (this.checked.sort().toString() !== arr.sort().toString()) {
        this.checked = arr;
      }
    },
  },

  created() {
    let val = this.$props.value;
    let arr = [];
    while (val.indexOf('有') !== -1) {
      arr.push(val.substring(val.indexOf('有') - 2, val.indexOf('有')));
      val = val.substring(val.indexOf('有') + 1, val.length);
    }
    if (this.checked.sort().toString() !== arr.sort().toString()) {
      this.checked = arr;
    }
  },

  mounted() {
  },

  unmounted() {
  },
}
</script>

<style scoped>

</style>