<template>
  <van-form @submit="onSubmit" ref="form">

    <!--            tab卡片页                    -->
    <van-tabs v-model:active="classID" sticky offset-top="36"
              :before-change="beforeTabChange">
      <van-tab v-for="(classItem, classIndex) in classList" :key="classIndex" :title="classItem.displayTitle">
        <!--        <van-collapse v-model="titleID" accordion>-->
        <!--          <van-collapse-item :name="titleItem.id" v-for="titleItem in classItem.titleList" :key="titleItem.id"-->
        <!--                             :title="titleItem.displayTitle">-->
        <van-cell-group inset style="margin-bottom: 16vw; margin-top: 4.26667vw">
          <template v-for="(fieldItem, fieldIndex) in classItem.detailList" :key="fieldIndex">

<!--            楼层展开参数                    -->
            <template v-if="fieldItem['param.code']==='NEWFLR'">
              <template v-for="(item, index) in floorList" :key="index">

                <van-field
                    v-model="formData[item['nf']]"
                    :name="item['nf']"
                    label="楼层"
                    :placeholder="'楼层'+ (index+1)"
                    clearable
                    :required="true"
                    :rules="[{ required: true, message: '请填写楼层' }]"
                    @focus="setChangeValue(item['nf'])"
                    @blur="getAssociatedParam(item['nf'])"
                ></van-field>

                <van-field
                    v-model="formData[item['dbflr']]"
                    :name="item['dbflr']"
                    label="楼层间距"
                    placeholder="楼层间距"
                    clearable
                    :required="true"
                    :rules="[{ required: true, message: '请填写楼层间距' }]"
                    @focus="setChangeValue(item['dbflr'])"
                    @blur="getAssociatedParam(item['dbflr'])"
                >
                  <template #extra>
                    <div>mm</div>
                  </template>
                </van-field>

                <DoorCheckBoxes
                    v-model:value="formData[item['frd']]"
                    :dataList="checkDataList"
                    :name="item['frd']"
                ></DoorCheckBoxes>
                <van-field
                    v-model="formData[item['oph']]"
                    type="number"
                    :name="item['oph']"
                    label="开门净高"
                    placeholder="开门净高"
                    :required="true"
                    :rules="[{ required: true, message: '请填写开门净高'}]"
                    @focus="setChangeValue(item['oph'])"
                    @blur="getAssociatedParam(item['oph'])"
                >
                  <template #extra>
                    <div>mm</div>
                  </template>
                </van-field>
              </template>
            </template>

<!--            表单参数                    -->
            <template v-else>
              <van-field
                  v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_TEXT'"
                  v-show="fieldItem.display"
                  v-model="formData[fieldItem['param.code']]"
                  :name="fieldItem['param.code']"
                  :label="fieldItem.displayName"
                  :placeholder="fieldItem['typeDic.name']"
                  clearable
                  show-error
                  :required="!fieldItem['allowBlank']"
                  :readonly="fieldItem.readOnly"
                  :rules="[{ required: !fieldItem['allowBlank'], message: '请填写'+fieldItem.displayName }]"
                  @focus="setChangeValue(fieldItem['param.code'])"
                  @blur="getAssociatedParam(fieldItem['param.code'])"
              ></van-field>
              <van-field
                  v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_NUMBER'"
                  v-show="fieldItem.display"
                  v-model="formData[fieldItem['param.code']]"
                  type="number"
                  :name="fieldItem['param.code']"
                  :label="fieldItem.displayName"
                  :placeholder="fieldItem['typeDic.name']"
                  :required="!fieldItem['allowBlank']"
                  :readonly="fieldItem.readOnly"
                  :rules="[{ required: !fieldItem['allowBlank'], message: '请填写'+fieldItem.displayName}]"
                  @focus="setChangeValue(fieldItem['param.code'])"
                  @blur="getAssociatedParam(fieldItem['param.code'])"
              >
                <!--            <template #input>-->
                <!--            <van-stepper-->
                <!--                v-model="formData[fieldItem['param.code']]"-->
                <!--                :decimal-length="fieldItem.decimalPrecision"-->
                <!--                min="fieldItem.minValue"-->
                <!--                :max="fieldItem.maxValue" />-->
                <!--          </template>-->
              </van-field>
              <van-field
                  v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_TEXTAREA'"
                  v-show="fieldItem.display"
                  v-model="formData[fieldItem['param.code']]"
                  rows="2"
                  clearable
                  autosize
                  type="textarea"
                  :name="fieldItem['param.code']"
                  :label="fieldItem.displayName"
                  :placeholder="fieldItem['typeDic.name']"
                  :required="!fieldItem['allowBlank']"
                  :rules="[{ required: !fieldItem['allowBlank'], message: '请填写'+fieldItem.displayName}]"
                  :readonly="fieldItem.readOnly"
                  @focus="setChangeValue(fieldItem['param.code'])"
                  @blur="getAssociatedParam(fieldItem['param.code'])"
              ></van-field>
              <van-field
                  v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_CHECKBOX'"
                  v-show="fieldItem.display"
                  type="checkbox"
                  v-model="formData[fieldItem['param.code']]"
                  :name="fieldItem['param.code']"
                  :label="fieldItem.displayName"
                  :placeholder="fieldItem['typeDic.name']"
                  :required="!fieldItem['allowBlank']"
                  :readonly="fieldItem.readOnly"
                  @focus="setChangeValue(fieldItem['param.code'])"
                  @blur="getAssociatedParam(fieldItem['param.code'])"
              >
                <template #input>
                  <van-checkbox v-model="formData[fieldItem['param.code']]">{{ fieldItem.displayName }}</van-checkbox>
                </template>
              </van-field>


              <template v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_COMBO'">
                <van-field
                    readonly
                    :label="fieldItem.displayName"
                    :name="fieldItem['param.code']"
                    v-model="formData[fieldItem['param.code']]"
                    :required="!fieldItem['allowBlank']"
                    :placeholder="fieldItem['typeDic.name']"
                    :rules="[{ required: !fieldItem['allowBlank'], message: '请选择'+fieldItem.displayName  }]"
                    @click="showPicker(fieldItem)"
                    @focus="setChangeValue(fieldItem['param.code'])"
                    @blur="getAssociatedParam(fieldItem['param.code'])"
                >
                </van-field>
                <van-popup v-model:show="fieldItem.show" position="bottom">
                  <van-picker
                      show-toolbar
                      :columns="pickerData[fieldItem['param.code']]"
                      :default-index="0"
                      @confirm="(val) => pickerConfirm(val, fieldItem)"
                      @cancel="pickerCancel(fieldItem)"
                      :title="fieldItem.displayName"
                  />
                </van-popup>
              </template>

              <template v-if="fieldItem['typeDic.code']==='PARAM_TPL_DETAIL_TYPE_IMAGE_PICKER'">
                <ImageSelect
                    v-model:value="formData[fieldItem['param.code']]"
                    :opData="imagePickerData[fieldItem['param.code']]"
                    :name="fieldItem['param.code']"
                    select-first
                ></ImageSelect>
              </template>

            </template>

          </template>
        </van-cell-group>
        <!--          </van-collapse-item>-->
        <!--        </van-collapse>-->


      </van-tab>

      <!--<van-tab v-for="item in testData" :key="item.id" :title="item.name">-->
      <!--  <ImageSelect-->
      <!--      v-model:value="item.val"-->
      <!--      :opData="item.images"-->
      <!--      :name="item.name"-->
      <!--  ></ImageSelect>-->
      <!--</van-tab>-->


    </van-tabs>

    <!--            参数总览页                  -->
    <van-popup v-model:show="isSubmit" position="bottom" :style="{ height: '90%' }" duration="0.5">
      <van-cell-group inset style="margin-bottom: 16vw; margin-top: 4.26667vw">
        <template v-for="detail in fieldList" :key="detail.id">

          <!--            参数                  -->
          <van-cell is-link :title="detail['displayName']" :value="formData[detail['param.code']]"
                    @click="editParam(detail.titleID)" v-if="imageInfo.indexOf(detail['param.code']) === -1 &&  detail['param.code'] !== 'NEWFLR'"/>

          <!--            楼层展开参数                  -->
          <van-cell is-link :title="detail['displayName']" v-if="imageInfo.indexOf(detail['param.code']) === -1 && detail['param.code'] === 'NEWFLR'"
                    @click="editParam(detail.titleID)">
            <template #label><p v-for="t in this.floorInfoText" :key="t">{{t}}</p></template>
          </van-cell>

          <!--            装潢                  -->
          <van-cell is-link :title="detail['displayName']"
                    :value="formData[detail['param.code']]"
                    v-if="imageInfo.indexOf(detail['param.code']) !== -1 && detail['param.code'] !== 'NEWFLR'"
                    @click="editParam(detail.titleID)">
            <template #label>
              <van-image
                  width="64"
                  height="64"
                  fit="cover"
                  :src="getImageUrl(detail['param.code'], formData[detail['param.code']])"
              />
            </template>
          </van-cell>



          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <!--          <template #right-icon>-->
          <!--            <van-tag style="margin-left: 1.06667vw" type="primary" size="medium" @click="editParam(detail.titleID)">更改-->
          <!--            </van-tag>-->
          <!--          </template>-->
          <!--        </van-cell>-->
        </template>
      </van-cell-group>
      <van-action-bar>
        <van-action-bar-button type="primary" text="返回" @click="onClickBackButton"/>
        <div style="width: 0.8vw;background-color: #ffffff"></div>
        <van-action-bar-button type="primary" text="提交" @click="onClickSubmitButton" native-type="submit"/>
      </van-action-bar>
    </van-popup>

    <!--            底部按钮                  -->
    <van-action-bar>
      <van-action-bar-button type="primary" text="上一步" @click="onClickPreButton" :disabled="preButtonDis"/>
      <div style="width: 0.8vw;background-color: #ffffff"></div>
      <van-action-bar-button type="primary" text="下一步" @click="onClickNextButton" v-if="!isLast"/>
      <div style="width: 0.8vw;background-color: #ffffff" v-if="canSubmit"></div>
      <van-action-bar-button type="primary" text="确定" @click="onClickConfirmButton" v-if="canSubmit"/>
    </van-action-bar>
  </van-form>
</template>

<script>
import _ from "lodash";
import {Toast} from "vant";
import ImageSelect from "./ImageSelect";
import DoorCheckBoxes from "./DoorCheckBoxes";

export default {

  name: "DynamicForm",

  components: {DoorCheckBoxes, ImageSelect},

  props: {
    show: {
      default: false,
      type: Boolean
    }
  },

  emits: ['update:show', 'onConfirm'],

  setup() {
  },

  data() {
    return {
      isSubmit: false,
      isReady: false,
      isOpened: false,
      index: 0,
      classList: [],
      titleList: [],
      formData: {},
      classID: '',
      titleID: '',
      pickerData: {},
      imagePickerData: {},
      changeVal: null,
      fieldList: [],
      floorInfoText: [],
      imageInfo: []
    };
  },

  computed: {
    preButtonDis: function () {
      return 0 === this.classID;
    },
    isLast: function () {
      return this.classID === this.classList.length - 1;
    },
    canSubmit: function () {
      return this.isReady || this.isLast;
    },
    floorList: function () {
      let num = parseInt(this.formData['NF_S']);
      let list = [];
      for (let i = 0; i < num; i++) {
        let NFText = 'NF' + i//楼层
        let DBFLRText = 'DBFLR' + i;//楼层间距mm
        let FRDText = "FRD" + i;
        let OPHText = 'OPH' + i;//开门净高mm
        let item = {
          nf: NFText,
          dbflr: DBFLRText,
          frd: FRDText,
          oph: OPHText
        };
        list.push(item);
      }
      let DoorDRC = this.formData['DoorDRC'];//多开门
      // name: '前' + FRDtext,
      // name: '后' + FRDtext, hidden: DoorDRC.indexOf('贯通') == -1 && DoorDRC.indexOf('双通') == -1,
      //name: '左' + FRDtext, hidden: DoorDRC.indexOf('左直角') == -1 && DoorDRC.indexOf('三面') == -1,
      // name: '右' + FRDtext,  hidden: DoorDRC.indexOf('右直角') == -1 && DoorDRC.indexOf('三面') == -1,
      /**
       *        xtype: 'numberfield',
       *                 name: 'OPH' + i,
       *                 emptyText: '开门净高',
       *                 label: '开门净高mm',
       */
      return list;
    },
    checkDataList: function () {
      return ['前门', '后门']
    },
  },

  watch: {},

  created() {

  },

  mounted() {
    this.getTplJson();
    this.getModelDate();
  },

  unmounted() {
  },

  methods: {
    //获取参数模板
    getTplJson() {
      var me = this;
      var option = {
        portName: 'epo.basic.param.ParamTpl.getParamTplJson',
        data: {id: "220526144848366202"},
        needLoading: true,
        anonymous: true,
        successCallback: (data) => {
          let formJson = data.data.formJson;
          var classList = me.classList;
          classList.length = 0;
          let classes = formJson.classList.flatMap((value) => {
            return value.titleList;
          })
          classList.push(...classes)


          for (let i = 0; i < 4; i++) {
            classList.push({
              id: i,
              displayTitle: '装潢' + i,
              detailList: [{
                'typeDic.code': 'PARAM_TPL_DETAIL_TYPE_IMAGE_PICKER',
                'param.code': "ZH" + i,
                displayName: '装潢' + i,
                titleID: i
              }]
            });
            this.imageInfo.push("ZH" + i);
          }
          this.imagePickerData = {
            'ZH0': [{
              val: '001',
              imgsrc: require('../../assets/img/访.png'),
              bigimgsrc: require('../../assets/img/访.png')
            },
              {
                val: '002',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              },
              {
                val: '003',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: require('../../assets/img/访.png')
              },
              {
                val: '004',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              }],
            'ZH1': [{
              val: '001',
              imgsrc: require('../../assets/img/访.png'),
              bigimgsrc: require('../../assets/img/访.png')
            },
              {
                val: '002',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              },
              {
                val: '003',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: require('../../assets/img/访.png')
              },
              {
                val: '004',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              }],
            'ZH2': [{
              val: '001',
              imgsrc: require('../../assets/img/访.png'),
              bigimgsrc: require('../../assets/img/访.png')
            },
              {
                val: '002',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              },
              {
                val: '003',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: require('../../assets/img/访.png')
              },
              {
                val: '004',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              }],'ZH3': [{
              val: '005',
              imgsrc: require('../../assets/img/访.png'),
              bigimgsrc: require('../../assets/img/访.png')
            },
              {
                val: '006',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              },
              {
                val: '007',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: require('../../assets/img/访.png')
              },
              {
                val: '008',
                imgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg",
                bigimgsrc: "http://browser9.qhimg.com/bdm/480_296_0/t01bbd94b90e850d1d3.jpg"
              }]
          };


          this.fieldList = classList.flatMap((value) => {
            return value.detailList;
          })
        }
      };
      this.$sapi.callPort(option);
    },

    //获取数据
    getModelDate() {
      var me = this;
      var option = {
        portName: 'epo.market.quote.QuoteModel.getQuoteModel',
        data: {id: "220622150823168001"},
        needLoading: true,
        anonymous: true,
        successCallback: (data) => {
          let mOptionJson = data.data.mOptionJson;
          let entity = data.entities[0];
          let paramJson = entity.paramJson;
          let paramData = JSON.parse(paramJson);
          paramData['ZH2'] = '004';
          me.formData = paramData;
          me.pickerData = mOptionJson;
        }
      };
      this.$sapi.callPort(option);
    },
    setChangeValue(key) {
      this.changeVal = this.formData[key];
    },

    //运算参数
    getAssociatedParam(paramCode) {
      var needLoading = true;
      var me = this;
      if (me.formData[paramCode] == me.changeVal) {
        return;
      }
      var option = {
        portName: 'epo.basic.param.ParamOperation.getAssociatedParam',
        data: {
          productModelID: "220711154806919202",
          paramCode: paramCode,
          paramDatas: me.formData,
          projectModelCacheID: '220622150813990001'
        },
        needLoading: false,
        anonymous: true,
        successCallback: (data) => {
          let associatedValJson = data.data.associatedValJson;
          let mAssociatedOptionJson = data.data.mAssociatedOptionJson;
          for (const valKey in associatedValJson) {
            this.formData[valKey] = associatedValJson[valKey];
          }
          for (const optionKey in mAssociatedOptionJson) {
            this.pickerData[optionKey] = mAssociatedOptionJson[optionKey];
          }
          var keys = Object.keys(associatedValJson);
          var paramCodes = ['BFL', 'DBFLR', 'NF', 'K', 'ET', 'N'];
          var key = this.arrContains(keys, paramCodes);
          if (!_.isEmpty(key)) {
            this.getAssociatedParam(key);
          }
        },
        finallyCallback: () => {
          needLoading = false;
          Toast.clear();
        }
      };
      setTimeout(function () {
        if (!needLoading) {
          return;
        }
        me.$sapi.showLoading(true, '运算中');
      }, 200);
      this.$sapi.callPort(option);
    },
    arrContains(arr, keys) {
        var bl = null;
        for (var i = 0; i < keys.length; i++) {
          if (arr.indexOf(keys[i]) !== -1) {
            bl = keys[i];
            break
          }
        }
        return bl;
    },
    onSubmit(vals) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定提交？',
      })
          .then(() => {
            console.log(vals)
            // var option = {
            //   portName: '',
            //   data: {ids: [id]},
            //   needLoading: false,
            //   successCallback: () => {
            //     this.$notify({type: 'success', message: '提交成功'})
            //   }
            // }
            // this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    showPicker(item) {
      item.show = true;
    },
    pickerConfirm(val, item) {
      var oldV = this.formData[item['param.code']]
      if (oldV != val) {
        this.formData[item['param.code']] = val;
        this.getAssociatedParam(item['param.code'])
      }
      item.show = false;
    },
    pickerCancel(item) {
      item.show = false;
    },
    beforeTabChange(index) {
      // 返回 false 表示阻止此次切换
      return false;
      // var names =  this.classList[this.classID].detailList.flatMap((v)=>{
      //   return v['param.code'];
      // })
      // this.$refs.form.validate(names).then(()=>{
      // }).catch((errArray)=>{
      //   Toast(errArray.flatMap(e=>e.message).join('\n'))
      //   return false;
      // })
      // 返回 Promise 来执行异步逻辑
      // return new Promise((resolve) => {
      //   // 在 resolve 函数中返回 true 或 false
      //   resolve(index !== 3);
      // });
    },
    onClickNextButton() {
      let me = this;
      if (this.classList.length - 1 == this.classID) {
        return;
      }
      //校验当前tab的参数
      let classListElement = this.classList[this.classID];
      if (classListElement == undefined) {
        return;
      }
      var names = classListElement.detailList.flatMap((v) => {
        return v['param.code'];
      });
      this.$refs.form.validate(names).then(() => {
        this.classID = this.classID + 1;
      }).catch((errArray) => {
        Toast(errArray.flatMap(e => e.message).join('\n'))
      })
    },
    onClickPreButton() {
      if (0 === this.classID) {
        return;
      }
      this.classID = this.classID - 1;
    },
    onClickBackButton() {
      this.isSubmit = false;
      this.isOpened = false;
    },
    onClickConfirmButton() {
      let me = this;
      this.isSubmit = true;
      this.isReady = true;

      //楼层参数
      let map = new Map();
      let formData = this.formData;
      this.floorList.forEach(f => {
        let nf = formData[f.nf];
        let key = '楼层间距' + formData[f.dbflr] + 'mm' + ',' + formData[f.frd] + ',' +
            '开门净高' + formData[f.oph] + 'mm'
        if (map.has(key)) {
          let val = map.get(key);
          val = val + ',' + nf;
          map.set(key, val);
        } else {
          map.set(key, '楼层:' + nf);
        }
      })
      this.floorInfoText = [];
      map.forEach((v, k) => {
        this.floorInfoText.push(v + "：" + k);
      })

      //防止点击过快直接跳到修改页
      setTimeout(function () {
        me.isOpened = true;
      }, 1000);
    },
    onClickSubmitButton() {
      this.$refs.form.validate().then(() => {

      }).catch((errArray) => {
        Toast(errArray.flatMap(e => e.message).join('\n'))
      })
    },
    getImageUrl(code, val) {
      let data = this.imagePickerData[code];
      for (let i = 0; i < data.length; i++) {
        if (data[i].val == val) {
          return data[i].imgsrc;
        }
      }
    },
    editParam(classID) {
      if (!this.isOpened) {
        return;
      }
      this.isSubmit = false;
      this.isOpened = false;
      let me = this;
      let number = this.classList.findIndex((value, index) => {
        if (value.id === classID) {
          return index;
        }
      });
      me.classID = number
    }
  }
}
</script>

<style scoped>

</style>